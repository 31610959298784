import ExpandMoreFilled from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { knownUserAgentsForMenu } from "utils/user-agent";
import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import ListItem from "@mui/material/ListItem";
import Grow from "@mui/material/Grow";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";

interface UserAgentOverrideDropdownProps {
  userAgentOverride: string;
  setUserAgentOverride: (userAgentOverride: string) => void;
}

export default function UserAgentOverrideDropdown({
  userAgentOverride,
  setUserAgentOverride,
}: UserAgentOverrideDropdownProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const getCurrentUserAgentOverride = () => {
    return userAgentOverride || knownUserAgentsForMenu[0];
  };

  return (
    <Box>
      <ButtonBase
        sx={{
          bgcolor: open ? "rgba(255, 255, 255, 0.06)" : "transparent",
          borderRadius: "8px",
          "&:hover": { bgcolor: "rgba(255, 255, 255, 0.09)" },
          minwidth: "116px",
          height: "42px",
          padding: "8px 2px 8px 11px",
        }}
        aria-label="open user agent override"
        ref={anchorRef}
        aria-controls={open ? "user-agent-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            variant="h6"
            sx={{ color: "#FFF", fontWeight: "700", letterSpacing: "0.46px" }}
          >
            {getCurrentUserAgentOverride()}
          </Typography>
          <ExpandMoreFilled sx={{ color: "white", width: "24px", height: "24px" }} />
        </Stack>
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 3],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
            <Paper
              sx={{
                padding: 0,
                width: {
                  xs: 230,
                  sm: 300,
                },
                borderRadius: "12px",
                boxShadow:
                  "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="user-agent-override">
                  <ListItem key="title" sx={{ px: "24px", py: "12px" }}>
                    <ListItemText
                      primary={
                        <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
                          User Agent Override
                        </Typography>
                      }
                    />
                    <ListItemIcon sx={{ marginRight: "-24px" }}>
                      <InfoOutlined />
                    </ListItemIcon>
                  </ListItem>
                  {knownUserAgentsForMenu.map((knownUserAgent: string, index: number) => [
                    <MenuItem
                      value={knownUserAgent}
                      key={`menu-item-${index}`}
                      onClick={() => {
                        setUserAgentOverride(knownUserAgent);
                        setOpen(false);
                      }}
                      sx={{ px: "24px", py: "12px" }}
                    >
                      <ListItemText
                        primary={knownUserAgent}
                        sx={{
                          whiteSpace: "normal",
                          maxWidth: {
                            xs: 182,
                            sm: 252,
                          },
                        }}
                      />
                      {knownUserAgent === getCurrentUserAgentOverride() && (
                        <ListItemIcon sx={{ marginRight: "-4px" }}>
                          <CheckCircleIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                      )}
                    </MenuItem>,
                    index !== knownUserAgentsForMenu.length - 1 && (
                      <Divider
                        key={`divider-${index}`}
                        sx={{
                          margin: "0px 24px !important",
                        }}
                      />
                    ),
                  ])}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
